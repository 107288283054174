import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as fromUserActions from './user.actions';
import * as fromChannelsListActions from '../channels-list';
import * as fromFeatureActions from '../feature-flags/index';
import { UserService, AdminApiService } from '@app/data/services';
import { UserInfo, Silo, SiloItem } from '@app/data/models';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiService, TrackingService } from '@app/core/services';
import { AppState } from '../root-store';
import { Store, Action } from '@ngrx/store';
import { selectChannelsList } from '../channels-list';
import { DOCUMENT } from '@angular/common';

import { selectUserInfo, selectUsersPreferences } from './user.selectors';
import { UsersPreferencesLSKey } from '@app/core/constants/userPreferences';
import { concatLatestFrom } from '@ngrx/operators';

const urlMinLength = 9;
@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private userService: UserService,
        private adminService: AdminApiService,
        private router: Router,
        private apiService: ApiService,
        private store: Store<AppState>,
        private trackingService: TrackingService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    setSilo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.setUserSilo),
            map((action) => action.userSilo),
            switchMap((silo) =>
                this.userService
                    .setUserSilo(silo.id)
                    .pipe(map(() => fromUserActions.setUserSiloSuccess({ userSilo: silo }))),
            ),
        ),
    );

    setUserSiloSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromUserActions.setUserSiloSuccess),
                tap(() => {
                    window.localStorage.setItem('ms4::is_silo', 'true');
                    this.router.navigate(['app']);
                }),
            ),
        { dispatch: false },
    );

    switchSilo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.switchUserSilo),
            map((action) => action.siloId),
            switchMap((siloId) =>
                this.userService.setUserSilo(siloId).pipe(
                    map(() => {
                        return fromUserActions.switchUserSiloSuccess();
                    }),
                    catchError(() => of({ type: '[User] Switch user silo error' })),
                ),
            ),
        ),
    );

    switchUserSiloSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.switchUserSiloSuccess),
            mergeMap(() => [
                fromChannelsListActions.resetChannelsList(),
                fromUserActions.getUserInfo(),
                fromUserActions.resetTrialDays(),
            ]),
        ),
    );

    getUserInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.getUserInfo),
            tap(() => {
                this.store.dispatch(fromUserActions.resetTrialDays());
            }),
            switchMap(() =>
                this.userService.getUserInfo().pipe(
                    map((userInfo: UserInfo) => {
                        if (userInfo) {
                            return fromUserActions.getUserInfoSuccess({ userInfo });
                        }
                    }),
                ),
            ),
        ),
    );

    getUserInfoSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.getUserInfoSuccess),
            tap(({ userInfo }) => {
                this.trackingService.setupTracking(userInfo);
                localStorage.setItem('ms4::is_logged_in', 'true');
            }),
            concatLatestFrom(() => this.store.select(selectChannelsList)),
            switchMap(([_, channelsList]) => {
                const actions: Action<any>[] = [
                    fromFeatureActions.getFeatureFlags(),
                    fromUserActions.getTrialDays(),
                    fromUserActions.getUserSilos(),
                ];
                if (channelsList?.length >= 0) actions.push(fromChannelsListActions.loadChannelsList());
                return actions;
            }),
        ),
    );

    updateUserSilo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.updateUserSilo),
            map((action) => action.updatedSilo),
            switchMap((updatedSilo) =>
                this.adminService.updateSilo(updatedSilo).pipe(
                    map(() => fromUserActions.updateUserSiloSuccess({ updatedSilo })),
                    catchError((error) => of(fromUserActions.updateUserSiloError(error?.error))),
                ),
            ),
        ),
    );

    logoutUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.logoutUser),
            switchMap(() =>
                this.userService.logout().pipe(
                    map(() => fromUserActions.logoutUserSuccess()),
                    catchError(() => of({ type: '[User] Logout user error' })),
                ),
            ),
        ),
    );

    logoutUserSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.logoutUserSuccess),
            tap(() => {
                this.userService.purgeAuth();
                localStorage.removeItem('ms4::is_logged_in');
                if (environment.logout_redirect_url && environment.logout_redirect_url.length > urlMinLength) {
                    window.location.href = environment.logout_redirect_url;
                } else {
                    if (!this.router.url.startsWith('/accept-invite')) this.router.navigate(['/login']);
                }
            }),
            mergeMap(() => [fromChannelsListActions.resetChannelsList()]),
        ),
    );

    getTrialDays$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.getTrialDays),
            map(() => {
                const trialDays = this.document.cookie.split(';').find((c) => c.includes('trial_days'));
                return fromUserActions.setTrialDays({ trialDays: trialDays ? trialDays.split('=')[1] : null });
            }),
        ),
    );

    resetTrailDays$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.resetTrialDays),
            tap(() => {
                this.document.cookie = 'trial_days' + '=;expires=-1;path=/;';
            }),
            map(() => fromUserActions.setTrialDays({ trialDays: null })),
        ),
    );

    getUserSilos$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.getUserSilos),
            switchMap(() =>
                this.userService
                    .getSilos()
                    .pipe(map((silos: SiloItem[]) => fromUserActions.getUserSilosSuccess({ silos }))),
            ),
            catchError(() => of({ type: '[User] Get user silos error' })),
        ),
    );

    updateUserPreferences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromUserActions.updateUsersPreferences),
            concatLatestFrom(() => this.store.select(selectUsersPreferences)),
            concatLatestFrom(() => this.store.select(selectUserInfo)),
            map(([[{ preferences }, currentUsersPreferences], userInfo]) => {
                const usersPreferences = { ...currentUsersPreferences };
                const currentUserPreferences = usersPreferences[userInfo.user.id];
                if (currentUserPreferences) {
                    usersPreferences[userInfo.user.id] = { ...currentUserPreferences, ...preferences };
                } else {
                    usersPreferences[userInfo.user.id] = { ...preferences };
                }
                return fromUserActions.updateUsersPreferencesSuccess({ preferences: usersPreferences });
            }),
        ),
    );

    updateUserPreferencesSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(fromUserActions.updateUsersPreferencesSuccess),
                tap(({ preferences }) => {
                    localStorage.setItem(UsersPreferencesLSKey, JSON.stringify(preferences));
                }),
            ),
        { dispatch: false },
    );
}
